import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const InputField = React.forwardRef(
  (
    {
      className,
      type,
      id,
      placeholder,
      disabled,
      labelText,
      onChange,
      value,
      min,
      max,
      hasError,
      hiddenLabel,
      variant,
      errorMessage,
      validMessage,
    },
    ref,
  ) => {
    const [isValid, setIsValid] = useState(false);

    const inputClasses = classNames({
      'b-form-control': true,
      'is-invalid': hasError,
      'is-valid': !hasError && isValid,
    });

    const handleBlur = () => {
      if (!hasError) {
        setIsValid(true);
      }
    };

    const Tag = variant || 'input';

    return (
      <div className={className || 'b-form-group'}>
        <label className={hiddenLabel ? 'sr-only' : 'b-control-label'} htmlFor={id}>
          {labelText}
        </label>
        <Tag
          className={inputClasses}
          type={type}
          name={id}
          id={id}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          onChange={onChange}
          min={min}
          max={max}
          ref={ref}
          aria-invalid={hasError ? true : undefined}
          onBlur={handleBlur}
        />

        <div className="valid-feedback">{validMessage}</div>
        <div className="invalid-feedback">{errorMessage}</div>
      </div>
    );
  },
);

InputField.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  labelText: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  min: PropTypes.string,
  max: PropTypes.string,
  hasError: PropTypes.bool,
  hiddenLabel: PropTypes.bool,
  variant: PropTypes.oneOf(['textarea', 'input']),
  validMessage: PropTypes.string,
  errorMessage: PropTypes.string,
};

InputField.defaultProps = {
  className: '',
  type: 'text',
  placeholder: '',
  disabled: false,
  validMessage: 'Thank you.',
  errorMessage: 'Please check and enter it again.',
  hiddenLabel: true,
  min: null,
  max: null,
  hasError: false,
  variant: 'input',
};

export default InputField;
